import head from "lodash/head";
import { action, observable } from "mobx";
import { asyncCommand, Command } from "react-mvvm";
import { ITibetClient } from "../../../shared/api/TibetClient";
import { CourseStructure } from "../../../shared/contentStructure/CourseStructure";
import { Url } from "../../../shared/models/Url";
import { IErrorService } from "../../../shared/services/ErrorService";
import { ILowLevelNavigationService } from "../../../shared/services/NavigationService";
import { HttpError } from "../../../shared/services/errors/HttpError";

export class BuyCourseViewModel {
  buyCourseCommand: Command;

  @observable selectedPriceOptionId = head(this.course.priceOptions)?.id;

  constructor(
    public course: CourseStructure,
    public groupName: string,
    private tibetClient: ITibetClient,
    private errorService: IErrorService,
    lowLevelNavigationService: ILowLevelNavigationService
  ) {
    this.buyCourseCommand = asyncCommand(
      async () => {
        if (this.selectedPriceOptionId) {
          try {
            await this.tibetClient.initiateBuying(
              this.course.id,
              this.selectedPriceOptionId,
              `${lowLevelNavigationService.siteOrigin}/student/purchase-confirmation?courseId=${this.course.id}`,
              `${lowLevelNavigationService.siteOrigin}${Url.toProductPage(course.urlParams)}/${Url.toCoursePage(
                course.urlParams
              )}`,
              `${lowLevelNavigationService.siteOrigin}${Url.toErrorPage({ errorType: "payment" })}`,
              sessionStorage.getItem("utm_source") ?? undefined,
              sessionStorage.getItem("utm_medium") ?? undefined,
              sessionStorage.getItem("utm_campaign") ?? undefined
            );
          } catch (responseError) {
            if (responseError instanceof Response) {
              const message = await responseError.text();
              this.errorService.showErrorSplash(new HttpError(responseError.status, message));
              throw message;
            }
            throw responseError;
          }
        }
      },
      () => !!this.selectedPriceOptionId
    );
  }

  @action.bound
  setSelectedOption(priceOptionId: string) {
    this.selectedPriceOptionId = priceOptionId;
  }
}
