import { action, computed, observable } from "mobx";
import { Command } from "react-mvvm";
import { ITrainingGroundService } from "../../../shared/api/BackendApi";
import { ITibetClient } from "../../../shared/api/TibetClient";
import { CourseStructure } from "../../../shared/contentStructure/CourseStructure";
import { IErrorService } from "../../../shared/services/ErrorService";
import { IGtmService } from "../../../shared/services/GtmService";
import { ILanguageService } from "../../../shared/services/LanguageService";
import { ILowLevelNavigationService } from "../../../shared/services/NavigationService";
import { BookmarkStore } from "../../../shared/stores/BookmarkStore";
import { IUserStore } from "../../../shared/stores/UserStore";
import { loadImages } from "../../../shared/utils";
import hintToggleDogImage from "../../../static/images/iconsAnimated/hint-dog.svg";
import hintToggleMessageImage from "../../../static/images/iconsAnimated/hint-message.svg";
import { GtmBasePage } from "../../GtmBasePage";
import { PaymentSidePanelViewModel } from "../../shared/paymentDialog/PaymentSidePanelViewModel";
import { FinishConfirmationModalViewModel } from "./finishConfirmationModal/FinishConfirmationModalViewModel";
import { TrainingGroundTheme } from "./themesSelector/ThemesSelector";
import { TrainingViewModel } from "./training/TrainingViewModel";
import { TrainingResultViewModel } from "./trainingResult/TrainingResultViewModel";

export type TrainingGroundState =
  | { _type: "loading" }
  | {
      _type: "themeSelector";
      themes: TrainingGroundTheme[];
      startTraining: Command<TrainingGroundTheme[]>;
      selectThemeIds: (newValues: string[]) => void;
    }
  | { _type: "training"; model: TrainingViewModel }
  | { _type: "results"; model: TrainingResultViewModel };

export class TrainingGroundViewModel extends GtmBasePage {
  @observable state: TrainingGroundState = { _type: "loading" };

  @observable finishConfirmationModal = new FinishConfirmationModalViewModel();

  @observable selectedThemeIds: string[] = [];

  @observable paymentSidePanelViewModel: PaymentSidePanelViewModel;

  startTraining: Command<TrainingGroundTheme[]>;

  endTraining = new Command(async (withConfirmation: boolean) => {
    const isConfirmed = withConfirmation ? await this.finishConfirmationModal.confirm() : true;

    if (!isConfirmed) {
      return;
    }

    if (this.state._type !== "training") {
      throw new Error("End training shouldn't be triggered from a different state than training");
    }

    this.state = {
      _type: "results",
      model: new TrainingResultViewModel(
        this.trainingGroundInfo.title,
        this.trainingGroundInfo.results,
        this.trainingGroundInfo.successThreshold,
        this.resetTraining,
        this.courseStructure,
        this.state.model.getVisitedTasks()
      ),
    };
  });

  resetTraining = new Command(async () => {
    this.finishConfirmationModal.cancel();
    this.state = { _type: "loading" };
    await this.loadData();
  });

  @computed get trainingGroundInfo() {
    const { trainingGroundInfos } = this.courseStructure;

    if (trainingGroundInfos === undefined || trainingGroundInfos.length === 0) {
      throw new Error("Training ground infos should be defined");
    }

    const info = trainingGroundInfos.find(t => t.slug === this.slug);

    if (info === undefined) {
      throw new Error("Training ground info for active training should be defined");
    }

    return info;
  }

  @computed get activeCourseParams() {
    return this.courseStructure.urlParams;
  }

  @computed get hasAccess() {
    return this.trainingGroundInfo.hasAccess;
  }

  constructor(
    private slug: string,
    private courseStructure: CourseStructure,
    private trainingGroundService: ITrainingGroundService,
    languageService: ILanguageService,
    errorService: IErrorService,
    gtm: IGtmService,
    tibetClient: ITibetClient,
    public navigation: ILowLevelNavigationService,
    bookmarkStore: BookmarkStore,
    public user: IUserStore,
    private initialThemeSlug?: string
  ) {
    super(languageService, gtm, errorService);

    this.startTraining = new Command(selectedThemes => {
      this.state = {
        _type: "training",
        model: new TrainingViewModel(
          selectedThemes,
          courseStructure,
          this.trainingGroundService,
          this.endTraining,
          this.trainingGroundInfo,
          bookmarkStore,
          courseStructure.urlParams
        ),
      };
    });

    this.paymentSidePanelViewModel = new PaymentSidePanelViewModel(
      tibetClient,
      this.courseStructure,
      errorService,
      navigation
    );
  }

  @action.bound
  private selectThemeIds(newValues: string[]) {
    this.selectedThemeIds = newValues;
  }

  protected async loadData() {
    await loadImages([hintToggleDogImage, hintToggleMessageImage]);

    const themes = await this.trainingGroundService.getTrainingGroundThemes({
      courseId: this.courseStructure.id,
      trainingGroundId: this.trainingGroundInfo.id,
    });

    if (this.initialThemeSlug) {
      const initialTheme = themes.find(theme => theme.slug === this.initialThemeSlug);

      if (initialTheme) {
        this.selectedThemeIds = [initialTheme.id];
      }
    }

    this.state = {
      _type: "themeSelector",
      themes,
      startTraining: this.startTraining,
      selectThemeIds: this.selectThemeIds,
    };
  }
}
