import { IRoute } from "react-mvvm";
import { RouteConfiguration } from "../../types/platform/infrastructure/RouteConfiguration";
import { App } from "../App";
import { PurchaseConfirmationViewModel } from "./PurchaseConfirmationViewModel";

export const addPurchaseConfirmationRouting = (route: IRoute<App, unknown, unknown>) => {
  const buyRedirectRoute = route.addRoute<PurchaseConfirmationViewModel>(
    RouteConfiguration.PurchaseConfirmation,
    PurchaseConfirmationViewModel,
    app => {
      const purchaseConfirmation = new PurchaseConfirmationViewModel(
        app.languageService,
        app,
        app.gtm,
        app.backendApi,
        app.userStore
      );

      return app.showChildPage(purchaseConfirmation);
    }
  );
  return buyRedirectRoute;
};
