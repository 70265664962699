import React from "react";
import { CourseColorDto } from "../../../types/courses/dto/CourseColorDto";
import { Button } from "../button/Button";

export interface CourseButtonProps {
  courseSlug: string;
  courseColor: CourseColorDto;
  onClick: (slug: string) => void;
}

export const CourseButton: React.FC<CourseButtonProps> = ({ courseSlug, courseColor, onClick }) => {
  const { backgroundColor, textColor, hoverColor } = courseColor;

  return (
    <Button
      type="solid"
      size="large"
      className="CourseButton"
      onClick={() => {
        onClick(courseSlug);
      }}
      style={
        {
          "--backgroundColor": backgroundColor,
          "--textColor": textColor,
          "--hoverColor": hoverColor,
        } as React.CSSProperties
      }
    >
      <span>Les mer</span>
    </Button>
  );
};
