import { History } from "history";
import { IHomeRoute, IRoutedPage, isEmptyPath } from "./index";
import { RouteBinding } from "./RouteBinding";
import { RouteDefinitionBase } from "./RouteDefinition";

export class HomeRouteDefinition<
    TModel extends IRoutedPage & { activate: () => void } & { handleLocationChange: () => void },
    TQuery
  >
  extends RouteDefinitionBase<TModel, {}, {}>
  implements IHomeRoute<TModel>
{
  model: TModel | null = null;
  constructor(public modelFactory: (query: Partial<TQuery>) => Promise<TModel> | TModel) {
    super();
  }

  async bind(history: History<any>) {
    let updatingLocation = false;
    let updatingBindings = false;

    const updateLocation = () => {
      if (updatingBindings) return;

      let newLocation = binding.getLocation();
      if (!newLocation.path.startsWith("/")) newLocation.path = "/" + newLocation.path;

      if (history.location.pathname.toLowerCase() != newLocation.path.toLowerCase()) {
        updatingLocation = true;
        try {
          history.push({ pathname: newLocation.path, hash: newLocation.hash });
        } finally {
          updatingLocation = false;
        }
      } else if (history.location.hash != newLocation.hash) {
        updatingLocation = true;
        try {
          history.replace({ pathname: newLocation.path, hash: newLocation.hash });
        } finally {
          updatingLocation = false;
        }
      }
    };

    const query = history.location.search.substr(1);
    const model = await this.modelFactory(this.parseQuery(query));
    const childBinding = await this.bindFirstMatchingChildToPath(
      updateLocation,
      model,
      history.location.pathname,
      query,
      history.location.hash.substr(1)
    );
    if (!childBinding && !isEmptyPath(history.location.pathname)) {
      await model.handleInvalidRoute();
    }
    const binding = new RouteBinding<TModel>(updateLocation, this, "", model, childBinding);

    const unlisten = history.listen(async (location, action) => {
      model.handleLocationChange();
      if (updatingLocation) return;
      updatingBindings = true;
      try {
        await binding.update(location.pathname, location.search.substr(1), location.hash.substr(1));
      } finally {
        updatingBindings = false;
      }
    });

    model.activate();

    return {
      page: model,
      destroy: () => {
        unlisten();
        binding.destroy();
      },
    };
  }

  getPath(params: {}) {
    return "/student";
  }
}
