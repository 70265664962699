import Cookies from "js-cookie";
import { computed, observable, action } from "mobx";
import { Popup } from "../../shared/components/popup/Popup";
import { IGtmService } from "../../shared/services/GtmService";
import { ArticleReferenceDto } from "../../types/config/dto/ArticleReferenceDto";
import { ConfigDto } from "../../types/config/dto/ConfigDto";

export const AcceptedCookiesConsentValue = "true";

export const NecessaryCookieName = "ga_necessary_consent";
export const AnalysisCookieName = "ga_analysis_consent";

export class CookiesConsentViewModel {
  consentText?: string;

  privacyPolicyText?: string;

  privacyPolicyArticle?: ArticleReferenceDto;

  @observable public cookiesDetailsModal = new Popup();

  @observable public cookiesConsentState = Cookies.get(NecessaryCookieName);

  @observable public selectedCookies = [NecessaryCookieName];

  @computed get shouldCookiesConsentBeOpen() {
    return this.cookiesConsentState !== AcceptedCookiesConsentValue;
  }

  constructor(private config: ConfigDto, private gtm: IGtmService) {
    this.consentText = this.config.cookiesConsentText;
    this.privacyPolicyText = this.config.privacyPolicyText;
    this.privacyPolicyArticle = this.config.privacyPolicyArticle;
  }

  @action.bound
  acceptCookies(cookieNames: string[]) {
    cookieNames.forEach(cookieName => {
      Cookies.set(cookieName, AcceptedCookiesConsentValue, { expires: 150 });
    });

    this.gtm.pushCookieConsentChanged();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.fbq("consent", "grant");
    this.cookiesConsentState = AcceptedCookiesConsentValue;
  }

  @action.bound
  toggleCookie(cookieName: string, value: boolean) {
    if (value) {
      this.selectedCookies.push(cookieName);
    } else {
      this.selectedCookies = this.selectedCookies.filter(selectedCookie => selectedCookie !== cookieName);
    }
  }
}
