import { observer } from "mobx-react";
import React from "react";
import { ActivityIndicator } from "../../shared/components/activityIndicator/ActivityIndicator";
import { PageTitle } from "../../shared/components/pageTitle/PageTitle";
import { useTranslations } from "../../shared/contexts/StaticTranslationContext";
import { DashboardViewModel } from "./DashboardViewModel";
import { DashboardHero } from "./components/dashboardHero/DashboardHero";
import { DashboardSections } from "./components/dashboardSections/DashboardSections";
import { InfoBanner } from "./components/infoBanner/InfoBanner";

export const DashboardView = observer(({ model }: { model: DashboardViewModel }) => {
  const translations = useTranslations();
  const {
    courseStructure,
    searchQuery,
    user,
    courseProgress,
    heroImage,
    isLoading,
    paymentSidePanelViewModel,
    toSearchPage,
  } = model;
  const { isInfoBannerDisplayed, infoBannerTitle, infoBannerDescription, infoBannerLink, urlParams } = courseStructure;

  return (
    <PageTitle title={translations.dashboard}>
      <article className="Dashboard">
        <div className="Dashboard__inner">
          <ActivityIndicator isLoading={isLoading}>
            {isInfoBannerDisplayed && user && (
              <InfoBanner
                userId={user.id}
                title={infoBannerTitle}
                description={infoBannerDescription}
                link={infoBannerLink}
                courseParams={urlParams}
                goBackText={translations.goToDashboard}
              />
            )}
            <DashboardHero
              navigation={model.navigation}
              courseStructure={courseStructure}
              isAuthenticated={user !== undefined}
              searchQuery={searchQuery}
              courseProgress={courseProgress}
              heroImage={heroImage}
              paymentSidePanelViewModel={paymentSidePanelViewModel}
              toSearchPage={toSearchPage}
            />
            <DashboardSections model={model} />
          </ActivityIndicator>
        </div>
      </article>
    </PageTitle>
  );
});
