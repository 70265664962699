import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { useEffect } from "react";
import { bindTo, property } from "react-mvvm";
import { Button } from "../../../../shared/components/button/Button";
import { Image, SanityImage } from "../../../../shared/components/image/Image";
import { Input } from "../../../../shared/components/input/Input";
import { Link } from "../../../../shared/components/link/Link";
import { NavLink } from "../../../../shared/components/link/NavLink";
import { PortableTextContent } from "../../../../shared/components/portableTextContent/PortableTextContent";
import { ProgressByType } from "../../../../shared/components/progressByType/ProgressByType";
import { ProgressRing } from "../../../../shared/components/progressRing/ProgressRing";
import { SvgIcon } from "../../../../shared/components/svgIcon/SvgIcon";
import { CourseStructure } from "../../../../shared/contentStructure/CourseStructure";
import { useTranslations } from "../../../../shared/contexts/StaticTranslationContext";
import { CourseProgress } from "../../../../shared/progressStructure/CourseProgress";
import { ILowLevelNavigationService } from "../../../../shared/services/NavigationService";
import { ImageDto } from "../../../../types/shared/dto/ImageDto";
import { useUrl } from "../../../../useRouter";
import { SearchQuery } from "../../../search/siteSearch/addSiteSearchRouting";
import { PaymentSidePanelViewModel } from "../../../shared/paymentDialog/PaymentSidePanelViewModel";
import { useDashboardProgressTexts } from "../../hooks/useDashboardProgressTexts";

interface DashboardHeroProps {
  searchQuery: SearchQuery;
  courseStructure: CourseStructure;
  courseProgress: CourseProgress;
  heroImage?: ImageDto;
  isAuthenticated: boolean;
  paymentSidePanelViewModel: PaymentSidePanelViewModel;
  toSearchPage: () => void;
  navigation: ILowLevelNavigationService;
}

export const DashboardHero = observer((props: DashboardHeroProps) => {
  const {
    searchQuery,
    courseStructure,
    courseProgress,
    heroImage,
    isAuthenticated,
    paymentSidePanelViewModel,
    toSearchPage,
    navigation,
  } = props;
  const { progress, progressPercent, progressByType, isAnyOfType, actionableStepsCount } = courseProgress;
  const { urlParams } = courseStructure;
  const translations = useTranslations();
  const url = useUrl();
  const progressDescription = useDashboardProgressTexts(progressPercent).description;
  const [themeUrl, setThemeUrl] = React.useState("");

  useEffect(() => {
    const path = window.location.pathname;
    const newPath = path.replace("/forside", "/temaer");
    setThemeUrl(newPath);
  }, []);

  const renderHeroImage = () => {
    return (
      heroImage && (
        <Image.Container aspectRatio="4/3" className="DashboardHero__imageContainer">
          <SanityImage {...heroImage} />
          {heroImage.credit && <Image.Credit text={heroImage.credit} />}
        </Image.Container>
      )
    );
  };

  const renderAuthenticatedHero = () => {
    return (
      <>
        <div className="DashboardHero__headerContainer">
          <p className="DashboardHero__title">{courseStructure.title}</p>
          {courseStructure.description && (
            <div className="DashboardHero__courseDescription">
              {paymentSidePanelViewModel.buyCourseVm.course.buyCourseSection.description &&
                courseStructure.id === "13ca6573-efcf-494c-b516-199ae10b8117" && (
                  <PortableTextContent
                    content={paymentSidePanelViewModel.buyCourseVm.course.buyCourseSection.description}
                  />
                )}
              <PortableTextContent content={courseStructure.description} />
            </div>
          )}
          <div className="DashboardHero__searchContainer">
            <form
              className="DashboardHero__action"
              onSubmit={event => {
                event.preventDefault();
                toSearchPage();
              }}
            >
              <Input
                label={translations.searchForContent}
                placeholder={translations.searchForContent}
                className="DashboardHero__input"
                {...bindTo(property(searchQuery, "phrase"))}
              />
              <Link
                type="button"
                buttonType="circle"
                className="DashboardHero__submit"
                to={url.toSearchPage(courseStructure.urlParams, searchQuery.phrase)}
              >
                <SvgIcon iconName="search" />
                <span className="visuallyHidden">{translations.search}</span>
              </Link>
            </form>
          </div>
        </div>
        {renderHeroImage()}
        <div className="DashboardHero__progressRing">
          <ProgressRing progress={progress} />
          <span className="DashboardHero__progressRingDescription">{progressDescription}</span>
        </div>

        {actionableStepsCount > 0 && (
          <div className="DashboardHero__progressByType">
            <ProgressByType stepsProgress={progressByType} isAnyOfType={isAnyOfType} />
          </div>
        )}
      </>
    );
  };

  const renderAnonymousHero = () => {
    return (
      <>
        <div className="DashboardHero__headerContainer">
          <div>
            <p className="DashboardHero__title">{courseStructure.title}</p>
            {courseStructure.description && (
              <div className="DashboardHero__courseDescription">
                {paymentSidePanelViewModel.buyCourseVm.course.buyCourseSection.description &&
                  courseStructure.id === "13ca6573-efcf-494c-b516-199ae10b8117" && (
                    <PortableTextContent
                      content={paymentSidePanelViewModel.buyCourseVm.course.buyCourseSection.description}
                    />
                  )}
                <PortableTextContent content={courseStructure.description} />
              </div>
            )}
          </div>
          {courseStructure.isLicensed && (
            <div className="DashboardHero__buttonContainer">
              {urlParams.productSlug !== "sykepleie" ? (
                <Button
                  className="DashboardHero__buyButton"
                  onClick={paymentSidePanelViewModel.show}
                  type="solid"
                  size="large"
                >
                  {translations.buyAccess}
                </Button>
              ) : (
                <Button
                  className="DashboardHero__buyButton"
                  onClick={() => navigation.goToTibetLogin(window.location.href)}
                  type="solid"
                  size="large"
                >
                  Logg inn
                </Button>
              )}

              <NavLink
                type="clear"
                to={themeUrl}
                hrefType={1}
                className="DashboardHero__link"
                activeClassName="DashboardHero__linkActive"
              >
                <span className="Header__linkText">Alle tema</span>
              </NavLink>
            </div>
          )}
        </div>
        {renderHeroImage()}
      </>
    );
  };

  return (
    <section className="DashboardHero">
      <h1 className="visuallyHidden">{translations.dashboard}</h1>
      <div
        className={classNames("DashboardHero__content", {
          "DashboardHero__content--authenticated": isAuthenticated,
          "DashboardHero__content--anonymous": !isAuthenticated,
        })}
      >
        {isAuthenticated ? renderAuthenticatedHero() : renderAnonymousHero()}
      </div>
    </section>
  );
});
