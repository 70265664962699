import { observer } from "mobx-react";
import React from "react";
import { ActivityIndicator } from "../../../shared/components/activityIndicator/ActivityIndicator";
import { PageTitle } from "../../../shared/components/pageTitle/PageTitle";
import { useTranslations } from "../../../shared/contexts/StaticTranslationContext";
import { assertUnreachable } from "../../../shared/typeUtils";
import { PaymentSidePanel } from "../../shared/paymentDialog/PaymentSidePanel";
import { TrainingGroundViewModel } from "./TrainingGroundViewModel";
import { FinishConfirmationModal } from "./finishConfirmationModal/FinishConfirmationModal";
import { ThemesSelector } from "./themesSelector/ThemesSelector";
import { TrainingView } from "./training/TrainingView";
import { TrainingResultView } from "./trainingResult/TrainingResultView";

interface TrainingGroundViewProps {
  model: TrainingGroundViewModel;
}

const TrainingGroundStateView = observer((props: TrainingGroundViewProps) => {
  const {
    model: { state, trainingGroundInfo, selectedThemeIds, hasAccess, paymentSidePanelViewModel, navigation, user },
  } = props;

  switch (state._type) {
    case "loading":
      return (
        <div className="TrainingGround__loaderWrapper">
          <ActivityIndicator isLoading />
        </div>
      );
    case "themeSelector":
      return (
        <ThemesSelector
          onConfirmThemesSelection={state.startTraining.execute}
          themes={state.themes}
          trainingGroundInfo={trainingGroundInfo}
          selectedThemeIds={selectedThemeIds}
          onSelectedThemeIdsChange={state.selectThemeIds}
          hasAccess={hasAccess}
          paymentSidePanelViewModel={paymentSidePanelViewModel}
          navigation={navigation}
          user={user}
        />
      );
    case "training":
      return <TrainingView model={state.model} />;
    case "results":
      return <TrainingResultView {...state.model} activeCourseParams={state.model.activeCourseParams} />;
    default:
      return assertUnreachable(state);
  }
});

export const TrainingGroundView = observer((props: TrainingGroundViewProps) => {
  const { model } = props;
  const translations = useTranslations();

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [model.state]);

  return (
    <PageTitle title={translations.trainingGround}>
      <article className="TrainingGround">
        <div className="TrainingGround__inner">
          <TrainingGroundStateView model={model} />
        </div>
        <FinishConfirmationModal model={model.finishConfirmationModal} />
        <PaymentSidePanel modal={model.paymentSidePanelViewModel} />
      </article>
    </PageTitle>
  );
});
