import axios from "axios";
import axiosInherit from "axios-inherit";
import { RouteConfiguration } from "../../types/platform/infrastructure/RouteConfiguration";
import { ILanguageService } from "../services/LanguageService";
import { INavigationService } from "../services/NavigationService";

export const initAxiosGlobalInterceptors = (navigation: INavigationService, language: ILanguageService) => {
  // This makes sure global interceptors don't get overriden when defining interceptor on local instances
  // See issue https://github.com/axios/axios/issues/993
  // N.B. this _has_ to be called before local instances are created, so be careful.
  axiosInherit(axios);
  axios.interceptors.response.use(
    ok => ok,
    err => {
      const response = err?.response;
      if (response?.status === 401 && response.headers["retry-after-login"]) {
        const params = new URLSearchParams(err.config.params).toString();
        const returnUrl = `${RouteConfiguration.BuyRedirect}?${params}`;
        const encodedReturnUrl = encodeURIComponent(returnUrl);
        navigation.goToTibetPurchaseLogin(encodedReturnUrl);
      } else if (response?.status === 401) {
        navigation.goToTibetLogin();
      }
      return Promise.reject(err);
    }
  );
  axios.interceptors.request.use(config => {
    return {
      ...config,
      params: {
        ...config.params,
        lang: language.lang,
      },
    };
  });
};
