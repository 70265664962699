import { IRoute } from "react-mvvm";
import { RouteConfiguration } from "../../types/platform/infrastructure/RouteConfiguration";
import { CourseViewModel } from "../course/CourseViewModel";
import { CourseParams } from "../course/addCourseRouting";
import { ProductParams } from "../product/addProductRouting";
import { TrainingGroundViewModel } from "./trainingGround/TrainingGroundViewModel";

export type TrainingGroundParams = {
  trainingGroundSlug: string;
};

export type TrainingGroundQueryParams = {
  initialThemeSlug: string;
};

export const addTrainingGroundsRouting = (courseRoute: IRoute<CourseViewModel, ProductParams, CourseParams>) => {
  const trainingGroundRoutes = courseRoute.addRoute<
    TrainingGroundViewModel,
    TrainingGroundParams,
    TrainingGroundQueryParams
  >(
    `${RouteConfiguration.TrainingGroundPage}/:trainingGroundSlug`,
    TrainingGroundViewModel,

    async (coursePage, params, queryParams) => {
      const { initialThemeSlug } = queryParams;
      const {
        courseStructure,
        backendApi,
        gtm,
        languageService,
        errorService,
        bookmarkStore,
        tibetClient,
        navigation,
        userStore,
      } = coursePage;
      const { trainingGroundSlug } = params;

      const trainingGroundViewModel = new TrainingGroundViewModel(
        trainingGroundSlug,
        courseStructure,
        backendApi,
        languageService,
        errorService,
        gtm,
        tibetClient,
        navigation,
        bookmarkStore,
        userStore,
        initialThemeSlug
      );

      return coursePage.showChildPage(trainingGroundViewModel);
    }
  );

  return trainingGroundRoutes;
};
