import flatten from "lodash/flatten";
import { computed } from "mobx";
import { ITestService, IVideoService } from "../../../shared/api/BackendApi";
import { BookmarkToggleViewModel } from "../../../shared/components/thumbnails/shared/BookmarkToggleViewModel";
import { createStepThumbnailViewModel } from "../../../shared/components/thumbnails/stepThumbnail/createStepThumbnailViewModel";
import { CourseStructure } from "../../../shared/contentStructure/CourseStructure";
import { ThemeStructure } from "../../../shared/contentStructure/ThemeStructure";
import { IErrorService } from "../../../shared/services/ErrorService";
import { IGtmService } from "../../../shared/services/GtmService";
import { ILanguageService } from "../../../shared/services/LanguageService";
import { ILowLevelNavigationService } from "../../../shared/services/NavigationService";
import { BookmarkStore } from "../../../shared/stores/BookmarkStore";
import { IProgressStore } from "../../../shared/stores/ProgressStore";
import { UserStore } from "../../../shared/stores/UserStore";
import { GtmBasePage } from "../../GtmBasePage";
import { IAppMotifProvider } from "../../shared/AppMotif";
import { CourseNavigator } from "../../shared/courseNavigator/CourseNavigator";
import { LessonListItemViewModel } from "../../shared/lessonList/LessonListItemViewModel";
import { PaymentSidePanelViewModel } from "../../shared/paymentDialog/PaymentSidePanelViewModel";
import { ThemeTrainingSectionViewModel } from "./themeTrainingSection/ThemeTrainingSectionViewModel";

export class ThemeViewModel extends GtmBasePage implements IAppMotifProvider {
  @computed get lessonList() {
    return this.themeStructure.lessonStructures.map(
      (lesson, index) =>
        new LessonListItemViewModel(
          index,
          lesson,
          this.progressStore,
          this.videoService,
          this.bookmarkStore,
          this.userStore
        )
    );
  }

  @computed get stepThumbnails() {
    return flatten(this.lessonList.map(lesson => lesson.stepThumbnails));
  }

  @computed get themeProgress() {
    return this.progressStore.courseProgress.getThemeProgressById(this.themeStructure.id);
  }

  @computed get appMotif() {
    return { color: this.themeStructure.themeColor };
  }

  @computed get relatedContent() {
    return this.themeStructure.relatedContentStepStructures.map(stepStructure =>
      createStepThumbnailViewModel(
        stepStructure.thumbnail,
        this.progressStore,
        this.videoService,
        new BookmarkToggleViewModel(stepStructure.id, this.bookmarkStore, this.userStore)
      )
    );
  }

  @computed get trainingSectionViewModel() {
    if (!this.themeStructure.relatedTrainingGround && this.themeStructure.testStructures.length === 0) {
      return;
    }

    return new ThemeTrainingSectionViewModel(this.themeStructure, this.paymentSidePanelViewModel, this.testService);
  }

  @computed get previousTheme() {
    return this.courseNavigator.getPreviousTheme(this.themeStructure);
  }

  @computed get nextTheme() {
    return this.courseNavigator.getNextTheme(this.themeStructure);
  }

  @computed get hasFullCourseAccess() {
    return this.activeCourseStructure.hasFullAccess;
  }

  constructor(
    public themeStructure: ThemeStructure,
    public videoService: IVideoService,
    public languageService: ILanguageService,
    public errorService: IErrorService,
    public progressStore: IProgressStore,
    public userStore: UserStore,
    public paymentSidePanelViewModel: PaymentSidePanelViewModel,
    public bookmarkStore: BookmarkStore,
    public gtm: IGtmService,
    public testService: ITestService,
    public courseNavigator: CourseNavigator,
    public activeCourseStructure: CourseStructure,
    public navigation: ILowLevelNavigationService
  ) {
    super(languageService, gtm, errorService);
    this.themeStructure = themeStructure;
    this.paymentSidePanelViewModel = paymentSidePanelViewModel;
  }

  protected async loadData() {
    this.isLoading = false;
  }
}
