import { observer } from "mobx-react";
import React, { useState } from "react";
import { Button } from "../../../../shared/components/button/Button";
import { Checkbox } from "../../../../shared/components/checkbox/Checkbox";
import { CheckboxGroup } from "../../../../shared/components/checkbox/CheckboxGroup";
import { List } from "../../../../shared/components/list/List";
import { PortableTextContent } from "../../../../shared/components/portableTextContent/PortableTextContent";
import { SvgIcon } from "../../../../shared/components/svgIcon/SvgIcon";
import { Title } from "../../../../shared/components/title/Title";
import { useTranslations } from "../../../../shared/contexts/StaticTranslationContext";
import { useSelectAllCheckbox } from "../../../../shared/hooks/useSelectAllCheckbox";
import { ILowLevelNavigationService } from "../../../../shared/services/NavigationService";
import { IUserStore } from "../../../../shared/stores/UserStore";
import { TrainingGroundInfoDto } from "../../../../types/courses/dto/TrainingGroundInfoDto";
import { ImageDto } from "../../../../types/shared/dto/ImageDto";
import { PaymentSidePanelViewModel } from "../../../shared/paymentDialog/PaymentSidePanelViewModel";
import { ThemeItem } from "./themeItem/ThemeItem";
import { ThemeSelectorImage } from "./themeSelectorImage/ThemeSelectorImage";

export interface TrainingGroundTheme {
  id: string;
  title: string;
  themeImage?: ImageDto;
  backgroundImage?: ImageDto;
}

interface ThemesSelectorProps {
  themes: TrainingGroundTheme[];
  onConfirmThemesSelection: (selectedThemes: TrainingGroundTheme[]) => void;
  trainingGroundInfo: TrainingGroundInfoDto;
  selectedThemeIds: string[];
  onSelectedThemeIdsChange: (newValues: string[]) => void;
  paymentSidePanelViewModel: PaymentSidePanelViewModel;
  hasAccess: boolean;
  navigation?: ILowLevelNavigationService;
  user?: IUserStore;
}

export const ThemesSelector = observer((props: ThemesSelectorProps) => {
  const {
    themes,
    onConfirmThemesSelection,
    trainingGroundInfo,
    selectedThemeIds,
    onSelectedThemeIdsChange,
    paymentSidePanelViewModel,
    hasAccess,
    navigation,
    user,
  } = props;
  const translations = useTranslations();
  const { title, themePickerDefaultImage, themePickerDescription } = trainingGroundInfo;
  const availableFilterValues = themes.map(t => t.id);
  const [hoveredTheme, setHoveredTheme] = useState<TrainingGroundTheme>();
  const { isSelectAllChecked } = useSelectAllCheckbox(
    availableFilterValues,
    onSelectedThemeIdsChange,
    selectedThemeIds
  );

  return (
    <div className="ThemesSelector">
      <div className="ThemeSelector__content">
        <Title className="ThemesSelector__title" level={1}>
          {title}
        </Title>
        <div className="ThemesSelector__descriptionWrapper">
          <PortableTextContent content={themePickerDescription} className="ThemesSelector__description" />
        </div>
        <form className="ThemesSelector__form">
          <CheckboxGroup
            name="theme-selector"
            value={selectedThemeIds}
            onChange={values => onSelectedThemeIdsChange(values)}
          >
            <CheckboxGroup.Fieldset legend={translations.chooseThemes}>
              {hasAccess && (
                <div className="ThemesSelector__allCheckboxWrapper">
                  <Checkbox
                    checked={isSelectAllChecked}
                    onChange={() => {
                      isSelectAllChecked
                        ? onSelectedThemeIdsChange([])
                        : onSelectedThemeIdsChange(availableFilterValues);
                    }}
                    id="all"
                  >
                    {isSelectAllChecked ? translations.clearSelection : translations.selectAll}
                  </Checkbox>
                </div>
              )}
              <List
                items={themes}
                renderItem={theme => (
                  <ThemeItem
                    onMouseEnter={() => setHoveredTheme(theme)}
                    onMouseLeave={() => {
                      if (hoveredTheme?.id === theme.id) {
                        setHoveredTheme(undefined);
                      }
                    }}
                    theme={theme}
                    paymentSidePanelViewModel={paymentSidePanelViewModel}
                    hasAccess={hasAccess}
                    navigation={navigation}
                    user={user}
                  />
                )}
                className="ThemesSelector__list"
                singleItemClassName="ThemesSelector__listItem"
              />
            </CheckboxGroup.Fieldset>
          </CheckboxGroup>
          <div className="ThemesSelector__actions">
            <Button
              size="large"
              className="ThemesSelector__confirmButton"
              htmltype="submit"
              disabled={!hasAccess || selectedThemeIds.length === 0}
              onClick={() => onConfirmThemesSelection(themes.filter(t => selectedThemeIds.indexOf(t.id) !== -1))}
            >
              {translations.startPractising}
              <SvgIcon iconName="right-arrow-line" />
            </Button>
          </div>
        </form>
      </div>
      <div className="ThemeSelector__sider">
        {themePickerDefaultImage && (
          <ThemeSelectorImage
            themePickerDefaultImage={themePickerDefaultImage}
            themes={themes}
            hoveredTheme={hoveredTheme}
          />
        )}
      </div>
    </div>
  );
});
