import classNames from "classnames";
import React from "react";
import { ReactSVG } from "react-svg";
import { Checkbox } from "../../../../../shared/components/checkbox/Checkbox";
import { useCheckbox } from "../../../../../shared/components/checkbox/useCheckbox";
import { Image, SanityImage } from "../../../../../shared/components/image/Image";
import { ILowLevelNavigationService } from "../../../../../shared/services/NavigationService";
import { IUserStore } from "../../../../../shared/stores/UserStore";
import darkLock from "../../../../../static/images/icons/dark-lock.svg";
import { PaymentSidePanelViewModel } from "../../../../shared/paymentDialog/PaymentSidePanelViewModel";
import { TrainingGroundTheme } from "../ThemesSelector";

interface ThemeItemProps {
  theme: TrainingGroundTheme;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  paymentSidePanelViewModel: PaymentSidePanelViewModel;
  hasAccess?: boolean;
  navigation?: ILowLevelNavigationService;
  user?: IUserStore;
}

export const ThemeItem = (props: ThemeItemProps) => {
  const { theme, onMouseEnter, onMouseLeave, hasAccess, navigation, user, paymentSidePanelViewModel } = props;
  const { id, title, themeImage } = theme;
  const { inputChecked, onInputChange } = useCheckbox(id);

  const onClick = () => {
    if (user?.isAuthenticated) {
      if (hasAccess) {
        onInputChange(id, inputChecked || false);
      } else {
        paymentSidePanelViewModel.show();
      }
    } else {
      navigation?.goToTibetLogin(window.location.href);
    }
  };

  return (
    <div
      className={classNames("ThemeItem", { "ThemeItem--checked": inputChecked })}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      role="presentation"
    >
      <Image.Container aspectRatio="1/1" className="ThemeItem__image">
        {themeImage && <SanityImage {...themeImage} />}
      </Image.Container>
      <p className="ThemeItem__title">{title}</p>
      {hasAccess ? (
        <div className="ThemeItem__checkboxWrapper">
          <Checkbox id={id} className="ThemeItem__checkbox">
            <span className="visuallyHidden">{title}</span>
          </Checkbox>
        </div>
      ) : (
        <div className="ThemeItem__iconWrapper">
          <ReactSVG src={darkLock} />
        </div>
      )}
    </div>
  );
};
