import { action, observable } from "mobx";
import { BackendApi } from "../../shared/api/BackendApi";
import { IErrorService } from "../../shared/services/ErrorService";
import { IGtmService } from "../../shared/services/GtmService";
import { ILanguageService } from "../../shared/services/LanguageService";
import { UserStore } from "../../shared/stores/UserStore";
import { RouteConfiguration } from "../../types/platform/infrastructure/RouteConfiguration";
import { GtmBasePage } from "../GtmBasePage";

export class PurchaseConfirmationViewModel extends GtmBasePage {
  @observable courseId = "";

  @observable courseTitle = "";

  @observable courseSlug = "";

  @observable productAndCourseUrl = "";

  @observable productIsLoading = true;

  @observable productIsLoaded = false;

  @observable courseIdQueryParam;

  constructor(
    public languageService: ILanguageService,
    public errorService: IErrorService,
    public gtm: IGtmService,
    private backendApi: BackendApi,
    private userStore: UserStore
  ) {
    super(languageService, gtm, errorService);
    this.courseIdQueryParam = new URLSearchParams(window.location.search).get("courseId");

    if (this.courseIdQueryParam) {
      this.getCourseInfo(this.courseIdQueryParam);
    }
  }

  @action.bound
  public async getCourseInfo(courseId: string) {
    this.courseId = courseId;
    const course = await this.backendApi.getCourseStructure({ id: courseId });
    const products = await this.backendApi.getProductsInfo({});
    const matchingProduct = products.flatMap(product =>
      product.courses.find(c => c.id === courseId) ? [product] : []
    );

    if (course && matchingProduct) {
      this.courseTitle = course.title;
      this.courseSlug = course.slug;
      this.productAndCourseUrl = `${RouteConfiguration.Student}/${matchingProduct[0].slug}/${course.slug}/forside`;
    } else {
      console.error("Could not find course or product");
    }
  }

  protected loadData = async () => {
    setTimeout(() => {
      this.isLoading = false;
      this.productIsLoading = false;
    }, 6000);
  };
}
